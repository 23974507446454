<template>
  <!-- 待匹配酒店 -->
  <div class="matchingHotel">
    <div class="nav">
      <div>
        <span>匹配状态</span>
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.matching"
          placeholder="请选择"
          @change="changeType"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 酒店查询 -->
      <el-form
        v-if="
          role.includes('all') ||
          role.includes('hr') ||
          role.includes('caiwu_jl') ||
          role.includes('market_jl') ||
          role.includes('operate_jl') ||
          role.includes('market_baofang_zg')
        "
        :inline="true"
        label-width="auto"
        :model="formInline"
        class="demo-form-inlines"
      >
        <el-form-item label="职位">
          <el-select
            size="mini"
            style="width: 120px"
            v-model="formInline.roleName"
            @change="getGroups"
            placeholder="请选择"
          >
            <el-option
              v-for="item in jobOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            size="mini"
            style="width: 120px"
            @change="getPersons"
            v-model="formInline.group"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in groupOptions"
              :key="item.keyy"
              :label="item.valuee"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员">
          <el-select
            size="mini"
            style="width: 120px"
            v-model="formInline.people"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in personOptions"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.valuee"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="success" @click="query">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 酒店列表 -->
    <div class="list">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="ctripHotelName" label="酒店名称" align="center">
        </el-table-column>
        <el-table-column prop="cityName" align="center" label="城市">
        </el-table-column>
        <el-table-column
          prop="hotelTel"
          align="center"
          label="电话"
          width="150px"
        >
        </el-table-column>
        <el-table-column prop="platform" align="center" label="平台">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.platform == "1"
                  ? "艺龙"
                  : scope.row.platform == "2"
                  ? "飞猪"
                  : scope.row.platform == "3"
                  ? "美团"
                  : scope.row.platform == "4"
                  ? "去哪儿"
                  : scope.row.platform == "5"
                  ? "携程"
                  : scope.row.platform == "6"
                  ? "百度"
                  : scope.row.platform == "7"
                  ? "萝卜"
                  : scope.row.platform == "8"
                  ? "天下房仓"
                  : scope.row.platform == "9"
                  ? "龙腾捷旅"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="hotelVestIdName" align="center" label="马甲">
        </el-table-column>
        <el-table-column prop="matching" align="center" label="匹配状态">
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.matching == '-1'" type="info"
              >未匹配
            </el-tag>
            <el-tag
              size="small"
              v-if="scope.row.matching == '2'"
              type="warning"
            >
              匹配中</el-tag
            >
            <el-tag size="small" v-if="scope.row.matching == '1'" type="success"
              >匹配成功</el-tag
            >

            <el-tag size="small" v-if="scope.row.matching == '0'" type="danger"
              >匹配失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="失败原因"
          prop="matchingRemarks"
        ></el-table-column>
        <el-table-column
          label="匹配ID"
          prop="matchingHotelId"
        ></el-table-column>
        <el-table-column label="操作" width="271">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="
                scope.row.matching != '1' ||
                scope.row.platform == '2' ||
                scope.row.platform == '1' ||
                scope.row.platform == '7'
              "
              type="primary"
              @click="manual(scope.row)"
              :checked="scope.row.matching == '2' ? true : false"
              >手动匹配</el-button
            >

            <el-button size="mini" type="danger" @click="delManual(scope.row)"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.matching == 2 && scope.row.platform == 3"
              size="mini"
              type="primary"
              @click="meiTuanManual(scope.row)"
              >匹配成功</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <MatchingCtrip :getMatchList="getMatchList" ref="ctrip" />
    <MatchingGo :getMatchList="getMatchList" ref="go" />
    <MatchingYilong :getMatchList="getMatchList" ref="yilong" />
    <MatchingLuobo :getMatchList="getMatchList" ref="luobo" />
    <MatchingFeizhu :getMatchList="getMatchList" ref="feizhu" />
    <MatchingMeituan :getMatchList="getMatchList" ref="meituan" />
    <MatchingFangcang :getMatchList="getMatchList" ref="fangcang" />
    <MatchingLongTeng  :getMatchList="getMatchList" ref="longteng" />
  </div>
</template>

<script>
import {
  getMatching_api,
  getGroupList_api,
  matchingMtPoi_api,
  deleteMatchingFailVest_api,
} from "../../../apis/hotel";
import { getGroups_api } from "../../../apis/workbench";
//引入local
import local from "@/utils/local.js";
import MatchingCtrip from "./MatchingCtrip.vue";
import MatchingGo from "./MatchingGo.vue";
import MatchingYilong from "./MatchingYilong";
import MatchingFeizhu from "./MatchingFeizhu.vue";
import MatchingMeituan from "./MatchingMeituan.vue";
import MatchingLuobo from "./MatchingLuobo.vue";
import MatchingFangcang from "./MatchingFangcang.vue";
import MatchingLongTeng from "./MatchingLongTeng.vue";
export default {
  components: {
    MatchingCtrip,
    MatchingGo,
    MatchingYilong,
    MatchingFeizhu,
    MatchingMeituan,
    MatchingLuobo,
    MatchingFangcang,
    MatchingLongTeng,
  },
  props: ["parentsData"],
  data() {
    return {
      //酒店查询表单
      formInline: {
        matching: "",
        roleName: "",
        group: "",
        people: "",
        goupId: "",
      },
      //匹配状态
      typeList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "匹配失败",
        },
        {
          value: "1",
          label: "匹配成功",
        },
        {
          value: "2",
          label: "匹配中",
        },
        {
          value: "-1",
          label: "未匹配",
        },
      ],
      ajaxVestId: "", // 接口请求vest的id
      ajaxGroupId: "", // 接口请求group的id
      ajaxPersonId: "", // 接口请求person的id
      groupOptions: [], // 组别选择
      personOptions: [], // 人员选择
      jobOptions: [
        { label: "全部", value: "" },
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
      ], // 职位选择
      // 组别
      groupList: [],
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //酒店表格
      //选项按钮

      // 选项卡按钮
      btnCur: "0",
      //角色
      role: JSON.stringify(local.get("role")),
      searchData: {
        isSearch: "1",
        page: "",
        rows: "",
        roleName: "",
        goupId: "",
        people: "",
      },
    };
  },
  created() {
    this.getMatchList();
  },
  methods: {
    getChooseDataFn() {
      let getChooseData = {
        hotelSonIds: this.hotelSonIds,
        roleName: this.formInline.roleName,
        goupId: this.groupOptions[this.formInline.group]
          ? this.groupOptions[this.formInline.group].keyy
          : "",
        people: this.formInline.people,
        matching: this.formInline.matching,
      };
      return getChooseData;
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.currentPage = 1;
      //   this.getAccount();
      this.getMatchList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      //   this.getAccount();
      this.getMatchList();
    },
    // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.formInline.group = "";
        this.formInline.people = "";
        this.formInline.goupId = "";
        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.formInline.people = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
      this.ajaxGroupId = this.groupOptions[val].keyy; // 组id
      this.formInline.goupId = this.groupOptions[val].keyy; // 组id
      // console.log(this.ajaxGroupId, "组id");
    },
    // 查询
    search() {
      // if (this.groupOptions[this.formInline.group]) {
      //   this.groupId = this.groupOptions[this.formInline.group].keyy;
      //   this.getMatchList(); // 调用查询函数
      // } else {
      //   this.groupId = "";
      //   this.getMatchList(); // 调用查询函数
      // }
      this.currentPage = 1;
      this.getMatchList();
    },
    // 清空
    clear() {
      this.formInline = {};
      this.groupOptions = [];
      this.personOptions = [];
    },
    // 获取酒店列表
    async getMatchList(obj) {
      let searchDataa = JSON.parse(JSON.stringify(this.searchData));
      let searchDataf = Object.assign(
        searchDataa,
        this.formInline,
        this.parentsData
      );
      // this.$parent.formInline
      searchDataf.page = this.currentPage;
      searchDataf.rows = this.count;

      let { code, data } = await getMatching_api(
        searchDataf
        //   {
        //   page: this.currentPage,
        //   rows: this.count,
        //   isSearch: "1",
        //   matching: this.formInline.matching,
        //   roleName: this.formInline.roleName,
        //   goupId: this.ajaxGroupId == undefined ? "" : this.ajaxGroupId,
        //   people: this.formInline.person,
        //   cityName: obj ? obj.city : "",
        //   provinceName: obj ? obj.province : "",
        //   ctripHotelName: obj ? obj.name : "",
        //   start: obj ? obj.star : "",
        //   typeCore: obj ? obj.tag : "",
        //   Ctrip: obj ? obj.vest : "",
        //   CtripStatus: obj ? obj.xiecheng : "",
        //   QunarStatus: obj ? obj.qunaer : "",
        //   MeiTuanStatus: obj ? obj.meituan : "",
        //   FilPigStatus: obj ? obj.feizhu : "",
        //   SameCityStatus: obj ? obj.yilong : "",
        // }
      );
      if (code == 0) {
        // console.log(data, "132132132132");
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // 手动匹配
    manual(row) {
      // console.log(row);
      if (row.platform == 5) {
        //携程
        this.$refs.ctrip.open(row);
        // this.getList()
      } else if (row.platform == 4) {
        // 去哪儿
        this.$refs.go.open(row);
      } else if (row.platform == 1) {
        // 艺龙
        this.$refs.yilong.open(row);
      } else if (row.platform == 2) {
        // 飞猪
        this.$refs.feizhu.open(row);
      } else if (row.platform == 3) {
        // 美团
        this.$refs.meituan.open(row);
      } else if (row.platform == 7) {
        // 萝卜
        this.$refs.luobo.open(row);
      } else if (row.platform == 8) {
        // 天下房仓
        this.$refs.fangcang.open(row);
      }else if (row.platform == 9) {
        // 龙腾捷旅
        this.$refs.longteng.open(row); 
      }
    },
    delManual(row) {
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delManualFn(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async delManualFn(row) {
      let { code, data } = await deleteMatchingFailVest_api({
        hotelSonVestId: row.id,
      });
      if (code == 0) {
        this.$message.success(data);
        this.search();
      }
    },
    // 选择状态
    changeType(data) {
      // console.log(data);
      this.formInline.matching = data;
      this.getMatchList();
    },

    // 查询筛选
    query() {
      this.currentPage = 1;
      this.getMatchList();
    },
    meiTuanManual(row) {
      this.$confirm("是否确定匹配成功？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.meiTuanManualFn(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async meiTuanManualFn(row) {
      let { code, data } = await matchingMtPoi_api({
        hotelSonVestId: row.id,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.search();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  background: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .btn {
    .el-button.active {
      background: #256def;
      color: #fff;
    }
  }

  .demo-form-inlines {
    height: 40px;
  }
}

.operation {
  color: #256ef1;
}
</style>